import './App.css'
import MainSection from './components/Main'
import AboutUsSection from './components/AboutUs'
import MedappDesktopSection from './components/MedappDesktop'
import MedappMobileSection from './components/MedappMobile'
import DemoMedappDesktopSection from './components/DemoMedappDesktop'
import DemoMedappMobileSection from './components/DemoMedappMobile'
import FooterSection from './components/Footer'
import { useEffect, useRef, useState } from "react"

function App () {
  const formRef = useRef(null)
  const aboutUs = useRef(null)
  const medapp = useRef(null)

  const [displayDesktopDetails, setDisplayDesktopDetails] = useState(true)



  useEffect(() => {
    //Hay que esperar que cargue el formulario de hubspot
    setTimeout(() => {
      if (window.location.href.includes('contactanos')) {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      }
    }, 1000)
  }, [])

  return (
    <>
      <MainSection formRef={formRef} aboutUs={aboutUs} medapp={medapp} />
      <AboutUsSection aboutUs={aboutUs} />
      <div className='flex w-full justify-center md:px-32 px-4 py-3 items-center justify-center'>
        <div className='flex bg-custom-grey px-3 py-3 rounded-lg items-center xl:w-1/2 w-full'>
          <p className={`font-montserrat md:text-2xl text-lg font-semibold mr-3 w-1/2 py-3 cursor-pointer flex items-center justify-center rounded-lg ${displayDesktopDetails ? 'bg-blue-custom' : ''}`}
            onClick={() => setDisplayDesktopDetails((state) => !state)}
          >Soy profesional</p>
          <p className={`font-montserrat md:text-2xl text-lg font-semibold mr-3 w-1/2 py-3 cursor-pointer flex items-center justify-center rounded-lg ${!displayDesktopDetails ? 'bg-blue-custom' : ''}`}
            onClick={() => setDisplayDesktopDetails((state) => !state)}
          >Soy paciente</p>
        </div>
      </div>
      {displayDesktopDetails &&
        <>
          <MedappDesktopSection medapp={medapp} />
          <DemoMedappDesktopSection />
          <div className='flex flex-col w-full justify-center md:px-32 px-4 py-3 items-center justify-center relative'>
            <p className="font-montserrat md:text-2xl text-xl font-extrabold my-6">¡Comenzá a usar Medapp!</p>
            <a href='https://app.medapp.com.ar/create-account' target='_blank' rel="noreferrer"><span className="font-montserrat md:text-xl text-md bg-custom-yellow md:px-6 px-3 py-2 rounded font-bold cursor-pointer mt-4" >Registrarme</span></a>
            <div className='flex w-[30vh] h-[30vh] rounded-full bg-sphera-yellow absolute bottom-0 right-0 -z-10 blur-[180px]' />
          </div>
        </>
      }
      {!displayDesktopDetails &&
        <>
          <MedappMobileSection medapp={medapp} />
          <DemoMedappMobileSection />
        </>
      }
      <FooterSection formRef={formRef} />
    </>
  )
}

export default App
