import DemoDesktop from '../assets/desktop-light.png'

const DemoMedappDesktop = () => {

  return (
    <div className="flex flex-col w-full justify-center md:px-32 px-4 py-8 items-center relative">
      <div className='flex w-[40vh] h-[40vh] rounded-full bg-sphera-accua absolute top-0 left-0 -z-10  blur-[150px]' />
      <p className="font-montserrat md:text-3xl text-lg font-bold mb-4">Sumate a la revolución de la medicina inteligente</p>
      <p className="font-montserrat md:text-xl text-md">Así funciona MedApp, la plataforma que te va a ayudar a trabajar con más comodidad y brindar una mejor atención para tus pacientes.</p>
      <div className="md:px-12 mt-4 max-w-[1200px]">
        <a href="https://www.youtube.com/watch?v=AyG3Bit49tw" target="_blank" rel="noreferrer"><img src={DemoDesktop} alt="Demo Neutrum" className="w-full h-full cursor-pointer" /></a>
      </div>
    </div>
  )
}

export default DemoMedappDesktop