import DemoDesktop from '../assets/mobile-light.png'
import GooglePlay from '../assets/google-play-store.png'
import AppleStore from '../assets/apple-store.png'


const DemoMedappDesktop = () => {

  return (
    <div className="flex flex-col w-full justify-center md:px-32 px-4 py-8 items-center relative">
      <div className='flex w-[40vh] h-[40vh] rounded-full bg-sphera-accua absolute top-0 left-0 -z-10  blur-[150px]' />
      <p className="font-montserrat md:text-3xl text-lg font-bold mb-4">Echá un vistazo a la app para pacientes</p>
      <p className="font-montserrat md:text-xl text-md">Todo eso vas a poder hacer en nuestra plataforma</p>
      <div className="md:px-12 mt-4 max-h-[600px] flex justify-center items-center">
        <a href="https://www.youtube.com/shorts/0rmxrJNaX20" target="_blank" rel="noreferrer"><img src={DemoDesktop} alt="Demo Neutrum" className="max-h-[600px] cursor-pointer" /></a>
        <div className='flex flex-col items-center'>
          <p className="font-montserrat md:text-3xl text-lg font-bold mb-4">¡Comenzá a usar Medapp!</p>
          <p className="font-montserrat md:text-xl text-lg mb-4">Disponible para descargar en:</p>
          <a href='https://play.google.com/store/apps/details?id=com.medapp.and' target='_blank' rel="noreferrer"><img src={GooglePlay} alt="Google play" className="w-44 cursor-pointer mt-4" /></a>
          <a href='https://apps.apple.com/ar/app/medapp/id6445894934' target='_blank' rel="noreferrer"><img src={AppleStore} alt="Google play" className="w-44 cursor-pointer mt-4" /></a>
        </div>
      </div>
      <div className='flex w-[30vh] h-[30vh] rounded-full bg-sphera-yellow absolute bottom-0 right-0 -z-10 blur-[180px]' />
    </div>
  )
}

export default DemoMedappDesktop