import HubspotForm from "react-hubspot-form"
import WhatsAppIcon from '../assets/whatsapp_logo.svg'
import MailIcon from '../assets/mail_icon.svg'

const Main = ({ formRef }) => {

  return (
    <div className="flex flex-col w-full items-center justify-center md:px-16 px-8 md:pt-10 pt-3" id='contactanos'>
      <div className="flex flex-col lg:flex-row w-full justify-between max-w-[1200px] py-8 gap-8 lg:gap-0">
        <div className="flex w-full flex-col relative">
          <div className="flex flex-col w-full">
            <p className="font-extrabold font-montserrat md:text-5xl text-xl">Contactanos</p>
            <div className="flex flex-col w-full">
              <p className="font-montserrat md:mt-12 mt-4 md:text-xl text-md">Dejanos tus preguntas o solicitudes en el formulario y te responderemos lo antes posible.</p>
              <p className="font-montserrat md:mt-8 mt-4 md:text-xl text-md">También, puedes contactarnos por:</p>
              <div className="flex items-center">
                <img src={MailIcon} alt="Mail" className="w-4 h-4 mr-3 mt-4 md:mt-8" />
                <p className="font-montserrat md:mt-8 mt-4 md:text-xl text-md">admin@medapp.com.ar</p>
              </div>
              <div className="flex items-center">
                <img src={WhatsAppIcon} alt="Instagram" className="w-4 h-4 mr-3 mt-2 md:mt-4" />
                <p className="font-montserrat md:mt-4 mt-2 md:text-xl text-md">+5491121645520</p>
              </div>
            </div>
          </div>
          <div className='flex w-[30vh] h-[30vh] rounded-full bg-sphera-accua absolute bottom-0 blur-[180px]' />
        </div>
        <div className="flex w-full justify-center pt-8 md:pt-0">
          <div className="flex md:px-10 px-6 rounded-xl w-96 pt-6 bg-white" ref={formRef}>
            <HubspotForm
              portalId="46901046"
              formId="75bdcdde-b2df-4f5b-8a96-ef1533081ee3"
              loading={<p className="font-montserrat">Cargando...</p>}
            />
          </div>
        </div>
      </div>
      <div className='flex w-full items-center justify-center'>
        <p className='font-montserrat my-8'>{`© ${new Date().getFullYear()} All Rights Reserved MONFIORE S.A.`}</p>
      </div>
    </div>
  )
}

export default Main