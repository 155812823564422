import tick from '../assets/tick.svg'
import MedappScreenshootOne from '../assets/medapp-mobile-one.png'
import MedappScreenshootTwo from '../assets/medapp-mobile-two.png'

const MedappMobile = ({ medapp }) => {

  return (
    <div className="flex w-full justify-center md:px-32 px-8 pt-10" ref={medapp}>
      <div className="flex flex-col md:flex-row max-w-[1200px] justify-center w-full py-10 md:px-12">
        <div className="flex flex-col w-full pr-6 justify-center">
          <p className="font-montserrat text-blue-custom font-semibold">Empezá hoy</p>
          <p className="font-montserrat md:text-4xl text-xl font-extrabold my-4">¿Cómo funciona?</p>
          <p className="font-montserrat">Simplemente bajá la aplicación MedApp desde <a href='https://play.google.com/store/apps/details?id=com.medapp.and' target='_blank' rel="noreferrer" className='text-blue-custom'>Play Store</a> o <a href='https://apps.apple.com/ar/app/medapp/id6445894934' target='_blank' rel="noreferrer" className='text-blue-custom'>App Store</a> y sumate a la revolución de la medicina inteligente.</p>
          <p className="font-montserrat text-blue-custom font-bold mt-8 mb-2">Ventajas y beneficios de usar Medapp:</p>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Agendá turnos de manera ágil y simple.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Recibí tus recetas en menos de 24hs.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Guardia 24/7.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Historia clínica en un solo lugar, siempre disponible.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Interconsultas on line entre especialistas.</span>
        </div>
        <div className="lg:flex hidden w-full md:pl-6 mt-8 md:mt-0 justify-end items-center md:items-start">
          <div className='flex w-full md:pl-12 justify-end pr-2'>
            <div className='flex w-full h-[80%] justify-center items-center'>
              <img src={MedappScreenshootOne} alt="Medapp screenshoots" className="h-[80%] rounded-xl" />
              <img src={MedappScreenshootTwo} alt="Medapp screenshoots" className="h-[80%] rounded-xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MedappMobile