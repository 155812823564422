import LogoMedapp from '../assets/medapp-logo.png'
import WebMobilePic from '../assets/web-mobile.png'

const scrollToRef = (ref) => window.scrollTo({
  top: ref.current.offsetTop,
  behavior: 'smooth'
})

const Main = ({ formRef, aboutUs }) => {

  return (
    <div className="flex bg-one-pager bg-no-repeat bg-cover bg-bottom min-h-[550px] lg:min-h-[90vh] w-full justify-start items-center md:px-16 px-8 flex-col pb-10 relative overflow-hidden">
      <div className="flex flex-col md:flex-row w-full justify-between max-w-[1200px] py-4 md:items-center md:mt-4">
        <div className="my-3 md:my-0">
          <img src={LogoMedapp} className="md:w-56 w-28" alt="MedApp Logo" />
        </div>
        <div className="hidden md:flex w-full md:pr-12 justify-end items-center">
          <p className="pl-10 font-montserrat cursor-pointer">Inicio</p>
          <p className="pl-10 font-montserrat cursor-pointer" onClick={() => {
            scrollToRef(aboutUs)
          }}>Quienes somos</p>
        </div>
        <div className="hidden md:flex">
          <span className="font-montserrat md:text-lg text-md bg-custom-yellow md:px-8 px-3 py-2 rounded font-bold cursor-pointer" onClick={() => {
            scrollToRef(formRef)
          }}>Contactanos</span>
        </div>
      </div>
      <div className="flex w-full h-[60vh] md:items-center 2xl:max-w-[1200px] max-w-[950px] md:pb-8">
        <div className="flex flex-col md:w-4/5 w-full z-10">
          <p className="font-montserrat 2xl:text-5xl text-2xl font-extrabold md:mb-8 mb-6">Bienvenidos a MedApp, la nueva plataforma de telemedicina creada por Médicos</p>
          <p className="font-montserrat xl:text-xl text-md mb-4">Un lugar donde pacientes y médicos se encuentran sin intermediarios. Descargá la app o simplemente crea tu perfil on line y hacé tu consulta hoy mismo.</p>
          <p className="font-montserrat xl:text-xl text-md mb-12">Sumate a Medapp, la app de los médicos.</p>
          <div className="flex">
            <span className="font-montserrat md:text-xl text-md bg-custom-yellow md:px-6 px-3 py-2 rounded font-bold cursor-pointer" onClick={() => {
              scrollToRef(formRef)
            }}>Conocer más</span>
          </div>
        </div>
      </div>
      <div className="lg:flex hidden absolute -right-16 top-[15%]">
        <img src={WebMobilePic} alt="MedApp web & mobile" className='xl:max-h-[80vh] max-h-[60vh]' />
      </div>
    </div>
  )
}

export default Main