import SobreNosotrosPic from '../assets/sobre-nosotros.png'

const AboutUs = ({ aboutUs }) => {

  return (
    <div className="flex w-full justify-center md:px-32 px-8 py-10 relative" ref={aboutUs}>
      <div className='flex w-[30vh] h-[30vh] rounded-full bg-sphera-yellow absolute top-0 right-0 -z-10 blur-[180px]' />
      <div className="flex flex-col lg:flex-row max-w-[1200px] justify-center w-full py-10 md:px-12 px-6 bg-white rounded-xl shadow-lg shadow-blue-shadow">
        <div className="flex w-full mt-8 md:mt-0 lg:justify-start justify-center items-center md:items-start">
          <img src={SobreNosotrosPic} alt="Logo Intermedia" className="md:w-3/4 w-full" />
        </div>
        <div className="flex flex-col w-full pr-6">
          <p className="font-montserrat text-blue-custom font-semibold ">Sobre nosotros</p>
          <p className="font-montserrat md:text-4xl text-xl font-extrabold my-4">¿Qué es Medapp?</p>
          <p className="font-montserrat">Conectate con un especialista en minutos desde tu casa o con tu celular y accedé a turnos inmediatos, solicitá tus recetas, estudios o consultá tu historial médico en un espacio digital seguro y accesible.</p>
          <p className="font-montserrat mt-4">Pensada y creada por especialistas de la salud, MedApp llega para revolucionar la medicina y conectar de manera simple, rápida y segura profesionales de la salud con pacientes, sin importar donde se encuentren.</p>
        </div>
      </div>
    </div>
  )
}

export default AboutUs