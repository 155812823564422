import tick from '../assets/tick.svg'
import MedappScreenshoot from '../assets/medapp-screenshots.png'

const MedappDesktop = ({ medapp }) => {

  return (
    <div className="flex w-full justify-center md:px-32 px-8 pt-10" ref={medapp}>
      <div className="flex flex-col md:flex-row max-w-[1200px] justify-center w-full md:py-10 md:px-12">
        <div className="flex flex-col w-full pr-6 justify-center">
          <p className="font-montserrat text-blue-custom font-semibold">Empezá hoy</p>
          <p className="font-montserrat md:text-4xl text-xl font-extrabold my-4">¿Cómo funciona?</p>
          <p className="font-montserrat">Simplemente creá tu perfil on line <a href='https://app.medapp.com.ar/create-account' target='_blank' rel="noreferrer" className='text-blue-custom'>aquí</a>  y sumate a la revolución de la medicina inteligente.</p>
          <p className="font-montserrat text-blue-custom font-bold mt-8 mb-2">Ventajas y beneficios de usar Medapp:</p>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Gestioná todos tus turnos en un solo lugar.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Enviá recetas electrónicas directamente desde la app.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Atendé consultas desde cualquier lugar y dispositivo.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Registrá la historia clínica de tus pacientes, sus avances en su tratamiento y compartila.</span>
          <span className="font-montserrat flex items-center mt-2"><img src={tick} alt="tick icon" className="w-5 mr-2" />Sin gastos fijos: Pagas sólo un 5% de comisión si realizas una teleconsulta.</span>
        </div>
        <div className="lg:flex hidden w-full md:pl-6 mt-8 md:mt-0 justify-end items-center md:items-start">
          <div className='flex w-full h-full md:pl-12 justify-end pr-2'>
            <div className='flex w-full justify-center items-center'>
              <img src={MedappScreenshoot} alt="Medapp screenshoots" className="w-[85%] rounded-xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MedappDesktop